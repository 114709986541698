import { generateUtilityClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Interweave } from 'interweave';

export const classes = generateUtilityClasses('RichZext', [
  'blockquote',
  'image',
  'imageWrapper',
  'root',
]);

export const InterweaveStyled = styled(Interweave)(({ theme }) => ({
  [`&.${classes.root}`]: {
    a: {
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    figure: {
      figcaption: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        marginTop: theme.spacing(2),
      },
      margin: 0,
      width: '100%',
    },
    h1: {
      ...theme.typography.h1,
    },
    h2: {
      ...theme.typography.h2,
    },
    h3: {
      ...theme.typography.h3,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    h4: {
      ...theme.typography.h4,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(3),
      },
    },
    h5: {
      ...theme.typography.h5,
    },
    [`& .${classes.imageWrapper}`]: {
      '& > span': {
        position: 'unset !important',
      },
      position: 'relative',
      [`& .${classes.image}`]: {
        height: 'unset !important',
        objectFit: 'contain',
        position: 'relative !important',
        width: '100%',
      },
    },
    h6: {
      ...theme.typography.h6,
      marginBottom: theme.spacing(1),
    },
    p: {
      ...theme.typography.body1,
      marginBottom: theme.spacing(3),
    },
  },
  [`& .${classes.blockquote}`]: {
    '&::before': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(0.75),
      content: '""',
      height: theme.spacing(10),
      left: 0,
      position: 'absolute',
      top: theme.spacing(0.5),
      width: theme.spacing(1.5),
    },
    margin: 0,
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
    },
    position: 'relative',
  },
}));
