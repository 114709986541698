import { faAngleRight, IconLookup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Hero } from '@grimme/components';
import { Box, CardContent, Typography } from '@mui/material';

import BreadcrumbsWrapper from '../breadcrumbs-wrapper/breadcrumbs-wrapper';
import { RichText } from '../rich-text';
import { ButterImageRoot, classes, Root } from './styles';
import { TBreadcrumbs } from 'lib/butter-types';
import { GrimmeGroupCareer } from 'lib/butter-types/pages/grimme-group-career';

export type CareerPageHeroProps = {
  breadcrumbs: TBreadcrumbs;
  hero: GrimmeGroupCareer['hero'];
};

export default function CareerPageHero({
  breadcrumbs,
  hero: {
    background_image,
    cta_button_label,
    cta_button_link,
    cta_icon_name,
    cta_icon_type,
    cta_subtitle,
    cta_title,
    subtitle,
    title,
  },
}: CareerPageHeroProps) {
  const image = (
    <ButterImageRoot
      priority
      showPlaceholder
      alt=""
      layout="fill"
      quality={100}
      src={background_image}
    />
  );

  const ctaCard = (
    <Root>
      <CardContent className={classes.cardContent}>
        <Box className={classes.boxWrapper}>
          <FontAwesomeIcon
            className={classes.fontAwesomeIcon}
            icon={
              {
                iconName: cta_icon_name,
                prefix: cta_icon_type,
              } as IconLookup
            }
          />
        </Box>
        <RichText html={cta_title} />
        <Typography className={classes.heroSubtitle} variant="body1">
          {cta_subtitle}
        </Typography>
        <Button
          fullWidth
          className={classes.heroButton}
          endIcon={<FontAwesomeIcon icon={faAngleRight} />}
          href={cta_button_link}
          variant="primary"
        >
          <Box className={classes.heroButtonLabel}>{cta_button_label}</Box>
        </Button>
      </CardContent>
    </Root>
  );

  return (
    <Hero
      background={image}
      breadcrumbs={<BreadcrumbsWrapper {...breadcrumbs} />}
      cta={ctaCard}
      subtitle={subtitle}
      title={title}
    />
  );
}
