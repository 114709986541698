import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import {
  ComponentDecider,
  extractIconData,
  populateAdditionalData,
} from '@grimme/buttery';
import { Layout } from 'components';
import CareerPageHero from 'components/career-page-hero/career-page-hero';
import { CareerPageJobsFilter } from 'components/career-page-jobs-filter/career-page-jobs-filter';
import { environment } from 'environment';
import { useUserCountryCode } from 'hooks';
import { useJobResources } from 'hooks/useJobResources/useJobResources';
import { getPage } from 'lib';
import { GrimmeGroupCareer } from 'lib/butter-types/pages/grimme-group-career';
import { seoImageResize } from 'lib/butter/seoImageResize';
import {
  getServerSideTranslations,
  withDefaultStaticProps,
} from 'lib/composable';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';
import { useFontAwesomeIconsEffect } from '~/hooks/useFontAwesomeIconsEffect';

export const getStaticProps = async ({
  locale,
  preview,
}: GetStaticPropsContext) => {
  return await withDefaultStaticProps({
    locale,
    preview,
    props: async ({ includeIcons }) => {
      const pageData = await getPage<GrimmeGroupCareer>(
        locale,
        preview,
        'grimme-group-career-deu',
      );

      const componentData = await populateAdditionalData(
        pageData.fields.content,
        locale,
        preview,
        environment.butterCMSToken,
      );

      includeIcons([
        ...(extractIconData(componentData) as IconLookup[]),
        {
          iconName: pageData.fields.hero.cta_icon_name,
          prefix: pageData.fields.hero.cta_icon_type,
        } as IconLookup,
      ]);

      return {
        componentData,
        locale,
        pageData,
        ...(await getServerSideTranslations(locale)),
      };
    },
    revalidate: environment.revalidationTime,
    revalidateOnError: 10,
  });
};

export default function IndexPage({
  componentData,
  globalSettings,
  icons,
  locale,
  pageData,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  useFontAwesomeIconsEffect(icons);

  const userCountryCode = useUserCountryCode();
  const { data: resourceData } = useJobResources(locale);
  const { t } = useTranslation();

  return (
    <>
      <NextSeo
        description={pageData.fields.seo.description}
        nofollow={pageData.fields.seo.no_index}
        noindex={pageData.fields.seo.no_index}
        openGraph={{
          images: [
            {
              alt: pageData.fields.seo.title,
              type: 'image/jpeg',
              url: seoImageResize(pageData.fields.hero.background_image),
            },
          ],
        }}
        title={pageData.fields.seo.title}
      />
      <Layout globalSettings={globalSettings}>
        <CareerPageHero
          breadcrumbs={pageData.fields.breadcrumbs}
          hero={pageData.fields.hero}
        />
        {resourceData && <CareerPageJobsFilter resources={resourceData} />}
        <ComponentDecider
          content={componentData}
          runtimeProps={{
            countryCode: userCountryCode,
            newsletterSubscriptionApiUrl: `${environment.newsletterSubscriptionApiUrl}?language=${locale}`,
            t,
          }}
        />
      </Layout>
    </>
  );
}
