import { Card, generateUtilityClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButterImage } from '../butter-image';

export const classes = generateUtilityClasses('CareerPageHero', [
  'boxWrapper',
  'button',
  'cardContent',
  'fontAwesomeIcon',
  'heroButton',
  'heroButtonLabel',
  'heroSubtitle',
]);

export const Root = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: 'block',
  maxWidth: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: 400,
  },
  [`& .${classes.boxWrapper}`]: {
    alignItems: 'center',
    background: `linear-gradient(290deg, ${theme.palette.primary.main} 0%, #6F0012 100%) !important`,
    borderRadius: '50%',
    color: theme.palette.common.white,
    display: 'flex',
    inset: 'auto 0 0 auto',
    justifyContent: 'center',
    margin: theme.spacing(0, 'auto'),
    maxHeight: '56px',
    maxWidth: '56px',
    minHeight: '56px',
    minWidth: '56px',
  },
  [`& .${classes.cardContent}`]: {
    padding: theme.spacing(3),
  },
  [`& .${classes.heroButton}`]: {
    textAlign: 'start',
  },
  [`& .${classes.heroButtonLabel}`]: {
    width: '100%',
  },
  [`& .${classes.heroSubtitle}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.fontAwesomeIcon}`]: {
    height: '32px',
    textAlign: 'center',
    verticalAlign: 'center',
    width: '32px',
  },
}));

export const ButterImageRoot = styled(ButterImage)(() => ({
  objectFit: 'contain',
}));
