import { ALLOWED_TAG_LIST, Node } from 'interweave';
import Image from 'next/legacy/image';
import { InterweaveStyled, classes } from './styles';

interface RichTextProps {
  html: string;
}

export function RichText(props: RichTextProps) {
  const { html } = props;
  return (
    <InterweaveStyled
      allowList={ALLOWED_TAG_LIST.concat('iframe')}
      className={classes.root}
      content={html}
      transform={transform} // Allow iframes for YouTube videos and such
    />
  );
}

function transform(
  node: HTMLElement & HTMLImageElement,
  children: Node[],
): React.ReactNode {
  switch (node.tagName) {
    case 'BLOCKQUOTE':
      return <div className={classes.blockquote}>{children}</div>;
    case 'IMG':
      return (
        <div className={classes.imageWrapper}>
          <Image
            alt={node.alt}
            className={classes.image}
            layout="fill"
            src={node.src}
          />
        </div>
      );
    default:
      break;
  }
}
